/*
 * @Author: lwp
 * @Descripttion: ...
 * @Date: 2021-06-11 16:39:06
 */
import request from "../../public/resource/lib/axios/request";

//查看文件
export function fn_View(params) {
    if(!params.source){
        params.source = "upload";
    }
    return request.get("File/View",params);
}
export function fn_View2(params) {
    if(!params.source){
        params.source = "upload";
    }
    return request.get2("File/View",params);
}
// 获取题目配置
export function fn_GetQuestionConfigList(param, showLoading) {
    return request.post("/Home/ViewForm", param, showLoading);
}
export function fn_GetCustormFormSubjectAndAnswer(param, showLoading) {
    return request.post("/Form/GetCustormFormSubjectForPreview", param, showLoading);
}
// 题目（预设与自定义表单）答案保存
export function fn_SaveCustomFormAndAnswer(param,showLoading) {
    return request.post("Form/SaveCustomForm",param,showLoading);
}
//下载
export function fn_Download(params) {
    return request.get("File/Download",params);
}
// 获取药物列表
export function fn_GetDrugList(param,showLoading) {
    return request.post("Drug/GetDrugList",param,showLoading);
}
//模糊查询数据
export function fn_FuzzyQueryData(param, showLoading) {
    return request.post("/Currency/FuzzyQueryData", param, showLoading);
}
// 获取区划级联接口，通过传递当前层级区划编码，获取子级区划编码信息列表
export function fn_GetSubDistrictByParentCode(param,showLoading) {
    return request.post("Home/GetSubDistrictByParentCode",param,showLoading);
}
//获取表单角色权限 （C+S通用接口）
export function fn_GetFormRolePower(params,showLoading) {
    return request.post("/Form/GetCustormFormSubjectForPreview",params,showLoading,"VUE_APP_URL_Customform");
}
// 保存表单角色权限
export function fn_SaveFormRolePower(param, showLoading) {
    return request.post("/Form/SaveFormRolePower", param, showLoading);
  }
// 获取耗材属性
export function fn_GetConsumableProperties(param, showLoading) {
    return request.post("/Consumable/GetConsumableProperties", param, showLoading);
}
// 获取多语言列表
export function fn_GetLanguageListByProduct(param, showLoading) {
    return request.post("/Language/GetLanguageListByProduct", param, showLoading);
}
// 保存多语言配置
export function fn_SaveLanguageConfig(param, showLoading) {
    return request.post("/Language/SaveLanguageConfig", param, showLoading);
}

// 获取数据范围(病患项目)
export function fn_GetDataRange(param,showLoading) {
    return request.post("Data/GetDataRange",param,showLoading);
}
//获取翻译
export function fn_GetTranslate(param,showLoading) {
    return request.post("Language/GetTranslate",param,showLoading);
}
//获取语言列表
export function fn_GetLanguageList(param,showLoading) {
    return request.post("Language/GetLanguageList",param,showLoading);
}
//添加系统翻译
export function fn_AddSystemTranslate(param,showLoading) {
    return request.post("Language/AddSystemTranslate",param,showLoading);
}