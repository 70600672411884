let loginInfo = {
  SSOStore: {
    // set 对象的 value 值如果为数组，且长度为1，则以 JSON.stringify 的方式本地存储
    set: {
      dimensionList: ['dimensionList'],
      realName: 'realName',
      powerName: 'powerName',
      jobNum: 'jobNum',
      hospitalId: 'hospitalId',
      roleCode: ['roleCode'],
      token: 'token',
      powerCodes: ['powerCodes'],
      jurisdiction: 'isEnabelMenuFilter', // 是否医生登录
      doctorID: 'doctorID',
      departmentID: 'departmentID',
      topicId: 'TopicID',
      TopicID: 'TopicID',
      areas: ['areas'],
      Type: 'Type',  // Care 项目类型 
      TopicName: 'TopicName',
      editRole: ['editRole'],
      systemTopLogo: 'systemTopLogo',
      systemName: 'systemName',
      isIncluedReportWhenRecordDisplay: ['isIncluedReportWhenRecordDisplay'], // Care4.8.0.1是否有手术记录功能
    },
    remove: {
      tabActive: 'tabActive',
      subMenuShow: 'subMenuShow'
    },
  },
  powerList: {
    'Power-心内科-Care-N-N-Login': {
      name: 'dms.common_CareName',
      url: SSOLoginUrl('Care') + '/link.html'
    },
    'Power-PMHospital-BackManager-N-N-Login': {
      name: 'dms.role_PatientManagementPlatform',
      url: SSOLoginUrl('病患后台') + '/main.html#/',
    },
    'Power-CustomForm-N-N-N-Login': {
      name: 'dms.role_FormManagementSystem',
      url: 'main.html',
    },
    'Power-心内科-Report-N-N-SystemMaintenance,Power-心内科-Care-N-N-DeleteReport,Power-心内科-Care-N-N-CancelCompleted,Power-心内科-Report-N-N-ReportWriting,Power-心内科-Care-N-N-CreateReport,Power-心内科-Care-N-N-EditReport': {
      name: 'dms.role_ReportSystem',
      url: SSOLoginUrl('不用编程report'),
    },
  },
}

export default loginInfo
