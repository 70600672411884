<template>
  <div class="login-wrap">
    <div class="box" v-for="(item, idx) in loginData" :key="idx">
      <p class="name">{{ $t(item.name) }}</p>
      <yl-button
        v-if="item.isShow"
        type="primary"
        plain
        @click="fn_GotoList(item)"
        >{{ $t('dms.common_enter') }}</yl-button
      >
    </div>
  </div>
</template>

<script>
import loginInfo from '../../assets/js/loginSSO'
import * as common from '../../api/common'

export default {
  name: 'selectSystem',
  data() {
    return {
      pageName: '',
      loginData: [],
    }
  },
  methods: {
    fn_GotoList(item) {
      window.location.href = item.url
    },
    fn_checkPowerCodes(powerCodes, key) {
      const powerCodesList = powerCodes
      const keyList = key.split(',')

      for (let code of powerCodesList) {
        if (keyList.includes(code)) {
          return true
        }
      }

      return false
    },
    fn_GetSystemName() {
      let systemName = localStorage.getItem('systemName')
      let powerCodes = JSON.parse(localStorage.getItem('powerCodes'))
      let powerList = loginInfo.powerList
      if (systemName === 'null'||systemName=='') {
        localStorage.setItem('systemName', this.$t('dms.common_CareName'))
        systemName = this.$t('dms.common_CareName');
      }
      // 如果退出登录再次刷新页面的时候应该调到登录页
      if (!powerCodes) {
        this.$router.push({ path: '/index' })
        return
      }
      for (const key in powerList) {
        // 初始化页面所有进入按钮默认不显示
        powerList[key].isShow = false
        powerList[key].auth = key
        if (this.fn_checkPowerCodes(powerCodes, key)) {
          if (key === 'Power-心内科-Care-N-N-Login' && (systemName !== 'null'||systemName=='')) {
            this.$set(powerList[key],'name',systemName);
            // powerList[key].name = systemName
          }
          this.loginData.push(powerList[key])
        }
      }
    },
    fn_PostMessage() {
      const localStorageItems = {}
      const set = loginInfo.SSOStore.set
      const storeInfo = {}

      for (const key in set) {
        localStorageItems[key] = key
      }

      Object.keys(localStorageItems).forEach((key) => {
        storeInfo[key] = localStorage.getItem(localStorageItems[key])
      })

      storeInfo['role'] = localStorage.getItem('role')
      storeInfo['copyRight'] = localStorage.getItem('copyRight')
      storeInfo['lang'] = localStorage.getItem('lang') || 'zh_CN'
      storeInfo['hospitalType'] = localStorage.getItem('hospitalType')
      storeInfo['pdfExport'] = localStorage.getItem('pdfExport')
      storeInfo['presetTopic'] = localStorage.getItem('presetTopic')
      storeInfo['FollowBtnFlag'] = localStorage.getItem('FollowBtnFlag')
      storeInfo['CustomFormStatus'] = localStorage.getItem('CustomFormStatus')
      storeInfo['SerialNumber'] = localStorage.getItem('SerialNumber')
      storeInfo['isOpenMultiLang'] = localStorage.getItem('isOpenMultiLang')
      storeInfo['CaseCollection'] = localStorage.getItem('CaseCollection')
      storeInfo['Languages'] = localStorage.getItem('Languages')
      storeInfo['autosave'] = localStorage.getItem('autosave')
      storeInfo['timeallocation'] = localStorage.getItem('timeallocation')
      
      // 目前表单项目的本地存储用的是 departmentId, 病患的是 departmentID，所以存了两个
      Object.keys(loginInfo.powerList).map((key) => {
        if (this.fn_checkPowerCodes(JSON.parse(storeInfo.powerCodes), key)) {
          if (loginInfo.powerList[key].url === 'main.html') {
            // 表单项目原有逻辑，hospitalId departmentId 默认值是 空字符串
            localStorage.setItem(
              'hospitalId',
              storeInfo.hospitalId !== 'null' ? storeInfo.hospitalId : ''
            )
            localStorage.setItem(
              'departmentId',
              storeInfo.departmentID !== 'null' ? storeInfo.departmentID : ''
            )
            loginInfo.powerList[key].isShow = true
            if (this.loginData.length === 1) {
              window.location.href = 'main.html'
            }
          } else {
            this.fn_CreateIframe(storeInfo, loginInfo.powerList[key].url)
          }
        }
      })
    },
    fn_CreateIframe(storeInfo, url) {
      const src = url
      // 动态创建一个不可见的iframe，在iframe中加载一个跨域HTML
      const iframe = document.createElement('iframe')

      iframe.setAttribute('id', url)
      iframe.setAttribute('style', 'display: none;')
      iframe.src = src
      // 使用postMessage()方法将token传递给iframe
      iframe.onload = () => {
        iframe.contentWindow.postMessage(storeInfo, url)

        // setTimeout(() => {
        //   iframe.remove()
        // }, 3000);
        // iframe.remove()
      }
      document.body.appendChild(iframe)
    },
    fn_ReceiveMessage(event) {
      this.loginData.map((item, idx) => {
        if (item.url.indexOf(event.origin) > -1) {
          if (item.auth === 'Power-心内科-Care-N-N-Login') {
            item.url = event.origin + '/' + event.data
          }
          if (this.loginData.length === 1) {
            window.location.href = item.url
          }
          item.isShow = true
        }
      })
      this.$set(this, 'loginData', [...this.loginData])
    },
  },
  mounted() {
    let that = this
    this.fn_GetSystemName()
    this.fn_PostMessage()

    window.addEventListener('message', that.fn_ReceiveMessage, false)
  },
}
</script>

<style lang="less" scoped>
.login-wrap {
  height: calc(100vh - 360px);
  display: flex;
  justify-content: center;
  background: url('../../../public/resource/images/bgImg.png') no-repeat;
  background-size: cover;
  padding-top: 360px;
  .box {
    width: 284px;
    height: 200px;
    text-align: center;
    margin-right: 80px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 0 3px rgba(7, 104, 171, 0.4);
    cursor: pointer;
    .name {
      font-size: 20px;
      color: #000;
    }
  }
  .yl-button.yl-button-plain.yl-button.yl-button-primary {
    width: 140px;
    height: 40px;
  }
  p {
    margin: 32px 0 80px 0;
  }
}
</style>
