// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../public/resource/images/bgImg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login-wrap[data-v-b388b29c]{height:calc(100vh - 360px);display:flex;justify-content:center;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:cover;padding-top:360px}.login-wrap .box[data-v-b388b29c]{width:284px;height:200px;text-align:center;margin-right:80px;border-radius:8px;background:#fff;box-shadow:0 0 3px rgba(7,104,171,.4);cursor:pointer}.login-wrap .box .name[data-v-b388b29c]{font-size:20px;color:#000}.login-wrap .yl-button.yl-button-plain.yl-button.yl-button-primary[data-v-b388b29c]{width:140px;height:40px}.login-wrap p[data-v-b388b29c]{margin:32px 0 80px 0}", ""]);
// Exports
module.exports = exports;
